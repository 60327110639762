import { useState } from "react"
import {useAuth} from '../context/authContext';
import { useNavigate} from 'react-router-dom';
import { Alert } from "./Alert";

export function Login() {

  const [user,setUser] = useState({
    email: '',
    password: '', 
  });
  
  const { login, loginWithGoogle } = useAuth(); 
  const navigate = useNavigate();
  const [error, setError] = useState();

  const handleChange = ({target: {name,value} }) => setUser({...user, [name]:value})
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await login(user.email,user.password); 
      navigate('/');
    } catch (error) {
      console.log(error.code);
      setError(error.message);
    }
  }

  const handleGoogleSignin = async () => {
     try {
      await loginWithGoogle();
      navigate("/");
     } catch (error) {
       setError(error.message);
     }
  }

    return (
      <div className="w-full max-w-xs m-auto">

        {error && <Alert message={error} />  }
         
         <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        
        <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email:</label>
        <input type="text"
         id="email"
          name="email" 
          placeholder="email@domain.com"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
           onChange={handleChange}
        />
        </div>
        <div className="mb-4">
        <label htmlFor="password">Password:</label>
        <input type="password" 
        id="password" 
        name="password" 
        placeholder="******"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
         onChange={handleChange}
        />
        </div>
        
       
        <button className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Login</button>

        <button onClick={handleGoogleSignin}>Login Google</button>
      </form>
      </div>
    )
  }