import { useState } from "react"
import {useAuth} from '../context/authContext';
import { useNavigate} from 'react-router-dom';
import { Alert } from "./Alert";
export function Register() {

  const [user,setUser] = useState({
    email: '',
    password: '', 
  });
  
  const { signup } = useAuth(); 
  const navigate = useNavigate();
  const [error, setError] = useState();

  const handleChange = ({target: {name,value} }) => setUser({...user, [name]:value})
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signup(user.email,user.password); 
      navigate('/');
    } catch (error) {
      console.log(error.code);
      setError(error.message);
    }
  }

    return (
      <div>

       {error && <Alert message={error} />  }
         
         <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input type="text" id="email" name="email" placeholder="email@domain.com"
           onChange={handleChange}
        />
        <label htmlFor="password">Password:</label>
        <input type="password" id="password" name="password" placeholder="******"
         onChange={handleChange}
        />
        <button>Register</button>
      </form>
      </div>
    )
  }