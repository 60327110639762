// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCYBfWLDs5-Arnh_ayg_klveAeMok1cVng",
  authDomain: "paypac-dev.firebaseapp.com",
  projectId: "paypac-dev",
  storageBucket: "paypac-dev.appspot.com",
  messagingSenderId: "28078038770",
  appId: "1:28078038770:web:849112a570cc26cc3eba6b"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)